import { hydrate } from "@/utils/form";
import CreditsPackItem from "@/classes/doinsport/CreditsPackItem";

export default class CreditsPack {
    id = null;
    name = null;
    description = null;
    nextoreProductReference = null;
    price = null;
    items = [];
    club = JSON.parse(localStorage.getItem('selectedClub'));

    constructor(object, options) {
        if ("undefined" !== typeof options) {
            if (options.serialize) {
                this.serialize(object);
            }
            if (options.deserialize) {
                this.deserialize(object);
            }
        }
    }

    serialize (object) {
        this.castToCurrency (object);
        hydrate(this, object);
    }
    deserialize (object) {
        this.refineCreditPackItems(object);
        hydrate(this, object);
        this.price = parseFloat(object.price) * 100;
    }
    castToCents (object) {
        object.price = parseFloat(object.price) * 100;
    }
    castToCurrency (object) {
        object.price = parseFloat(object.price) / 100;
    }
    refineCreditPackItems (object) {
        let items = object.items;

        let deserializedItems = [];

        for (const item of items) {
            deserializedItems.push(new CreditsPackItem(item, { deserialize_with_recent: true }))
        }

        object.items = deserializedItems;
    }
}
