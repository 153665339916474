<template>
  <div>
    <b-row class="mb-4">
      <b-col class="settings-title" cols="10">
        {{ $t('views.client.details.body.left.credits-pack-affect.select-credits-pack') }}
      </b-col>
    </b-row>
    <d-table
      identifier="creditsPack"
      sticky-header="540px"
      :tablefields="creditsPackFields"
      :items="creditsPacks"
      :total-rows="creditsPacks.length"
      :per-page="500"
      :is-busy="isBusy"
      :show-pagination="false"
      :trCursorPointer="true"
      @row-clicked="onRateRowSelect"
      @on:radio-button-rate-check="onRateRowSelect"
    />
  </div>
</template>
<script>
import {getCreditPacks} from "@api/doinsport/services/payment-token-packs/payment-token-packs.api";
import CreditsPack from "@/classes/doinsport/CreditsPack";

export default {
  data: () => ({
    creditsPacks: [],
    isBusy: false
  }),
  props: {
    reload: {
      type: Boolean,
      default: false
    },
    selectedCreditsPack: {
      type: Object,
      default: () => ({
        values: null,
        selected: false,
      })
    },
  },
  watch: {
    reload: {
      deep: true,
      handler () {
        this.loadCreditsPacks();
      }

    }
  },
  computed: {
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    alignCellsToLeftWithCustomWidth() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center w-5' : 'text-left w-5';
    },
    creditsPackFields() {
      return [
        {
          key: "singleSelectionRate",
          class: this.alignCellsToLeftWithCustomWidth,
          label: "",
          sortable: false
        },
        {
          key: "name",
          label: this.$t("views.client.details.body.left.subscription-affect.rates-table.name"),
          sortable: true
        },
        {
          key: "creditPack",
          label: this.$t("views.subscriptions.index.table-credit.pack-credit")
        },
        {
          key: "price", label: this.$t("views.client.details.body.left.subscription-affect.rates-table.rate")
        },
      ];
    },
  },
  methods: {
    onRowSelect(row) {
      this.selectedCreditsPack.selected = true;
      this.selectedCreditsPack.values = row;
    },
    onRateRowSelect(row) {
      for (const rate of this.creditsPacks) {
        if (row.id === rate.id) {
          rate.singleSelectionRate = 'active';
          row.singleSelectionRate = 'active';
          this.onRowSelect(row);
        } else {
          rate.singleSelectionRate = false;
        }
      }
    },
    loadCreditsPacks() {
      this.creditsPacks = [];
      this.isBusy = true;

      getCreditPacks(500, 1, '')
        .then(response => {
          for (const creditsPack of response.data['hydra:member']) {
            const serializedCreditPack = new CreditsPack(creditsPack, {serialize: true});
            Object.assign(serializedCreditPack, {singleSelectionRate: false})
            this.creditsPacks.push(serializedCreditPack);
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
    }
  },
  created() {
    this.loadCreditsPacks();
  }
}
</script>
