import {hydrate, randomString} from "@/utils/form";

export default class CreditsPackItem {
    id = null;
    quantity = null;
    paymentToken = null;

    constructor(object, options) {
        if ("undefined" !== typeof options) {
            if(options.serialize) {
                this.serialize(object);
            }
            if(options.deserialize) {
                this.deserialize(object);
            }

            if(options.deserialize_with_recent) {
                this.deserializeRecent(object);
            }

            if(options.recent) {
                Object.assign(this, { recent: true });
                this.id = randomString();
            }
        }
    }

    serialize (object) {
        hydrate(this, object);
    }
    deserialize (object) {
        hydrate(this, object);
    }
    deserializeRecent (object) {
        if ("undefined" !== typeof object.recent) {
            object.id = null
            delete object.recent;
        }
        object.quantity = parseFloat(object.quantity);
        hydrate(this, object);
    }

}
